<template>
  <div>
    <div class="flex gap-2 justify-end border-b border-purple-300 pb-2">
      <div>
        <VaButton  preset="secondary" border-color="primary" @click="rotate90">Rotate 90 &deg;</VaButton>
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
    <VuePdfEmbed annotation-layer text-layer :source="displayUrl"
     :width="width" :rotation="rotationMe" :scale="scale"/>
  </div>
</template>

<script lang="ts" setup>
import VuePdfEmbed from 'vue-pdf-embed'
import 'vue-pdf-embed/dist/style/index.css'
// optional styles
import 'vue-pdf-embed/dist/style/annotationLayer.css'
import 'vue-pdf-embed/dist/style/textLayer.css'
const props = defineProps(["pdfSource", "width", "rotation", "scale"])
 
const displayUrl = computed(() => {
  if(typeof props.pdfSource == "undefined" ) return ""
  if(typeof props.pdfSource == "string") return props.pdfSource
  if(Array.isArray(props.pdfSource)  && props.pdfSource.length) return props.pdfSource[0]
 
  return props.pdfSource
})  
const rotationMe = ref(0)
const rotate90 = ()=>{
  rotationMe.value = (rotationMe.value + 90)
}
//const pdfSource = 'https://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf'
// const pdfSource = 'https://firebasestorage.googleapis.com/v0/b/dbbsclient.appspot.com/o/uploads%2Fmega_mart%2FMET2023-7-27%2FMARCH%2021%202023.pdf?alt=media&token=668ce063-51c3-4675-9006-720976ee56ab'
</script>

<style>

</style>